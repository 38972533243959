<div class="row">
  <div class="col-md-6 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Normal buttons</h4>
        <p class="card-description">Use any of the available button classes to quickly create a styled button.</p>
        <div class="template-demo">
          <button type="button" class="btn btn-primary btn-fw">Primary</button>
          <button type="button" class="btn btn-secondary btn-fw">Secondary</button>
          <button type="button" class="btn btn-success btn-fw">Success</button>
          <button type="button" class="btn btn-danger btn-fw">Danger</button>
          <button type="button" class="btn btn-warning btn-fw">Warning</button>
          <button type="button" class="btn btn-info btn-fw">Info</button>
          <button type="button" class="btn btn-light btn-fw">Light</button>
          <button type="button" class="btn btn-dark btn-fw">Dark</button>
          <button type="button" class="btn btn-link btn-fw">Link</button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Inverse buttons</h4>
        <p class="card-description">Use any of the available button classes to quickly create a styled button.</p>
        <div class="template-demo">
          <button type="button" class="btn btn-inverse-primary btn-fw">Primary</button>
          <button type="button" class="btn btn-inverse-secondary btn-fw">Secondary</button>
          <button type="button" class="btn btn-inverse-success btn-fw">Success</button>
          <button type="button" class="btn btn-inverse-danger btn-fw">Danger</button>
          <button type="button" class="btn btn-inverse-warning btn-fw">Warning</button>
          <button type="button" class="btn btn-inverse-info btn-fw">Info</button>
          <button type="button" class="btn btn-inverse-light btn-fw">Light</button>
          <button type="button" class="btn btn-inverse-dark btn-fw">Dark</button>
          <button type="button" class="btn btn-link btn-fw">Link</button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Rounded buttons</h4>
        <p class="card-description">Use any of the available button classes to quickly create a styled button.</p>
        <div class="template-demo">
          <button type="button" class="btn btn-primary btn-rounded btn-fw">Primary</button>
          <button type="button" class="btn btn-secondary btn-rounded btn-fw">Secondary</button>
          <button type="button" class="btn btn-success btn-rounded btn-fw">Success</button>
          <button type="button" class="btn btn-danger btn-rounded btn-fw">Danger</button>
          <button type="button" class="btn btn-warning btn-rounded btn-fw">Warning</button>
          <button type="button" class="btn btn-info btn-rounded btn-fw">Info</button>
          <button type="button" class="btn btn-light btn-rounded btn-fw">Light</button>
          <button type="button" class="btn btn-dark btn-rounded btn-fw">Dark</button>
          <button type="button" class="btn btn-link btn-rounded btn-fw">Link</button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Inverse rounded buttons</h4>
        <p class="card-description">Use any of the available button classes to quickly create a styled button.</p>
        <div class="template-demo">
          <button type="button" class="btn btn-inverse-primary btn-rounded btn-fw">Primary</button>
          <button type="button" class="btn btn-inverse-secondary btn-rounded btn-fw">Secondary</button>
          <button type="button" class="btn btn-inverse-success btn-rounded btn-fw">Success</button>
          <button type="button" class="btn btn-inverse-danger btn-rounded btn-fw">Danger</button>
          <button type="button" class="btn btn-inverse-warning btn-rounded btn-fw">Warning</button>
          <button type="button" class="btn btn-inverse-info btn-rounded btn-fw">Info</button>
          <button type="button" class="btn btn-inverse-light btn-rounded btn-fw">Light</button>
          <button type="button" class="btn btn-inverse-dark btn-rounded btn-fw">Dark</button>
          <button type="button" class="btn btn-link btn-rounded btn-fw">Link</button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Default buttons</h4>
        <p class="card-description">Use any of the available button classes to quickly create a styled button.</p>
        <div class="template-demo">
          <button type="button" class="btn btn-primary">Primary</button>
          <button type="button" class="btn btn-secondary">Secondary</button>
          <button type="button" class="btn btn-success">Success</button>
          <button type="button" class="btn btn-danger">Danger</button>
          <button type="button" class="btn btn-warning">Warning</button>
          <button type="button" class="btn btn-info">Info</button>
        </div>
      </div>
      <div class="card-body">
        <h4 class="card-title">Outlined buttons</h4>
        <div class="template-demo">
          <button type="button" class="btn btn-outline-primary">Primary</button>
          <button type="button" class="btn btn-outline-secondary">Secondary</button>
          <button type="button" class="btn btn-outline-success">Success</button>
          <button type="button" class="btn btn-outline-danger">Danger</button>
          <button type="button" class="btn btn-outline-warning">Warning</button>
          <button type="button" class="btn btn-outline-info">Info</button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Button sizes</h4>
        <div class="wrapper template-demo">
          <button type="button" class="btn btn-light btn-lg">btn-lg</button>
          <button type="button" class="btn btn-primary btn-lg">btn-lg</button>
        </div>
        <div class="wrapper template-demo">
          <button type="button" class="btn btn-light">btn-md</button>
          <button type="button" class="btn btn-primary">btn-md</button>
        </div>
        <div class="wrapper template-demo">
          <button type="button" class="btn btn-light btn-sm">btn-sm</button>
          <button type="button" class="btn btn-primary btn-sm">btn-sm</button>
        </div>
        <div class="wrapper template-demo">
          <button type="button" class="btn btn-light btn-xs">btn-xs</button>
          <button type="button" class="btn btn-primary btn-xs">btn-xs</button>
        </div>
      </div>
      <div class="card-body">
        <h4 class="card-title">Icon buttons</h4>
        <div class="template-demo">
          <button type="button" class="btn btn-success"><i class="mdi mdi-cloud-download"></i>Upload</button>
          <button type="button" class="btn btn-warning"><i class="mdi mdi-message-text"></i>Comments</button>
          <button type="button" class="btn btn-danger"><i class="mdi mdi-upload"></i>Upload</button>
          <button type="button" class="btn btn-info"><i class="mdi mdi-delete"></i>Delete</button>
          <button type="button" class="btn btn-primary"><i class="mdi mdi-printer"></i>Print</button>
          <button type="button" class="btn btn-success"><i class="mdi mdi-loop"></i>Reset</button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Grouped buttons</h4>
        <p class="card-description">These are the different buttons group component</p>
        <div class="template-demo">
          <div class="btn-group" role="group" aria-label="Basic example">
            <button type="button" class="btn btn-outline-secondary">1</button>
            <button type="button" class="btn btn-outline-secondary">2</button>
            <button type="button" class="btn btn-outline-secondary">3</button>
          </div>
        </div>
        <div class="template-demo">
          <div class="btn-group" role="group" aria-label="Basic example">
            <button type="button" class="btn btn-success">1</button>
            <button type="button" class="btn btn-success">2</button>
            <button type="button" class="btn btn-success">3</button>
          </div>
        </div>
        <div class="template-demo">
          <div class="btn-group" role="group" aria-label="Basic example">
            <button type="button" class="btn btn-outline-secondary icon-btn"><i class="mdi mdi-heart-outline"></i></button>
            <button type="button" class="btn btn-outline-secondary icon-btn"><i class="mdi mdi-clock"></i></button>
            <button type="button" class="btn btn-outline-secondary icon-btn"><i class="mdi mdi-border-color"></i></button>
          </div>
        </div>
        <div class="template-demo">
          <div class="btn-group" role="group" aria-label="Basic example">
            <button type="button" class="btn btn-danger icon-btn"><i class="mdi mdi-heart-outline"></i></button>
            <button type="button" class="btn btn-danger icon-btn"><i class="mdi mdi-clock"></i></button>
            <button type="button" class="btn btn-danger icon-btn"><i class="mdi mdi-border-color"></i></button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <h4 class="card-title">Button toolbar</h4>
        <div class="template-demo">
          <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
            <div class="btn-group" role="group" aria-label="First group">
              <button type="button" class="btn btn-secondary">1</button>
              <button type="button" class="btn btn-secondary">2</button>
              <button type="button" class="btn btn-secondary">3</button>
            </div>
            <div class="btn-group" role="group" aria-label="Second group">
              <button type="button" class="btn btn-secondary">5</button>
              <button type="button" class="btn btn-secondary">6</button>
            </div>
            <div class="btn-group" role="group" aria-label="Third group">
              <button type="button" class="btn btn-secondary">8</button>
            </div>
          </div>
        </div>
        <div class="template-demo">
          <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
            <div class="btn-group" role="group" aria-label="First group">
              <button type="button" class="btn btn-primary">1</button>
              <button type="button" class="btn btn-primary">2</button>
            </div>
            <div class="btn-group" role="group" aria-label="Second group">
              <button type="button" class="btn btn-primary">5</button>
              <button type="button" class="btn btn-primary">6</button>
              <button type="button" class="btn btn-primary">7</button>
            </div>
            <div class="btn-group" role="group" aria-label="Third group">
              <button type="button" class="btn btn-primary">8</button>
            </div>
          </div>
        </div>
        <div class="template-demo">
          <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
            <div class="btn-group" role="group" aria-label="First group">
              <button type="button" class="btn btn-success">1</button>
              <button type="button" class="btn btn-success">2</button>
              <button type="button" class="btn btn-success">3</button>
            </div>
            <div class="btn-group" role="group" aria-label="Second group">
              <button type="button" class="btn btn-success">5</button>
              <button type="button" class="btn btn-success">6</button>
            </div>
            <div class="btn-group" role="group" aria-label="Third group">
              <button type="button" class="btn btn-success">8</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Social Buttons</h4>
        <p class="card-description">These are the different buttons group component</p>
        <div class="template-demo">
          <button type="button" class="btn social-btn btn-primary"><i class="mdi mdi-facebook"></i></button>
          <button type="button" class="btn social-btn btn-info"><i class="mdi mdi-twitter"></i></button>
          <button type="button" class="btn social-btn btn-success"><i class="mdi mdi-dribbble"></i></button>
          <button type="button" class="btn social-btn btn-danger"><i class="mdi mdi-linkedin"></i></button>
          <button type="button" class="btn social-btn btn-warning"><i class="mdi mdi-google-plus"></i></button>
          <br>
          <button type="button" class="btn social-btn btn-facebook"><i class="mdi mdi-facebook"></i></button>
          <button type="button" class="btn social-btn btn-twitter"><i class="mdi mdi-twitter"></i></button>
          <button type="button" class="btn social-btn btn-dribbble"><i class="mdi mdi-dribbble"></i></button>
          <button type="button" class="btn social-btn btn-linkedin"><i class="mdi mdi-linkedin"></i></button>
          <button type="button" class="btn social-btn btn-google"><i class="mdi mdi-google-plus"></i></button>
        </div>
      </div>
      <div class="card-body">
        <h4 class="card-title">Social buttons rounded</h4>
        <div class="template-demo">
          <button type="button" class="btn social-btn btn-primary btn-rounded"><i class="mdi mdi-facebook"></i></button>
          <button type="button" class="btn social-btn btn-info btn-rounded"><i class="mdi mdi-twitter"></i></button>
          <button type="button" class="btn social-btn btn-success btn-rounded"><i class="mdi mdi-dribbble"></i></button>
          <button type="button" class="btn social-btn btn-danger btn-rounded"><i class="mdi mdi-linkedin"></i></button>
          <button type="button" class="btn social-btn btn-warning btn-rounded"><i class="mdi mdi-google-plus"></i></button>
          <br>
          <button type="button" class="btn social-btn btn-facebook btn-rounded"><i class="mdi mdi-facebook"></i></button>
          <button type="button" class="btn social-btn btn-twitter btn-rounded"><i class="mdi mdi-twitter"></i></button>
          <button type="button" class="btn social-btn btn-dribbble btn-rounded"><i class="mdi mdi-dribbble"></i></button>
          <button type="button" class="btn social-btn btn-linkedin btn-rounded"><i class="mdi mdi-linkedin"></i></button>
          <button type="button" class="btn social-btn btn-google btn-rounded"><i class="mdi mdi-google-plus"></i></button>
        </div>
      </div>
    </div>
  </div>
</div>