import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mdi',
  templateUrl: './mdi.component.html',
  styleUrls: ['./mdi.component.scss']
})
export class MdiComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
