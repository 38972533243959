<nav class="sidebar sidebar-offcanvas" id="sidebar">
  <ul class="nav pt-5">
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['']"  id="nav-link1">
        <i class="menu-icon mdi mdi-view-dashboard"></i>
        <span class="menu-title">Dashboard</span>
        <i class="menu-arrow"></i>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['/users']" id="nav-link1">
        <i class="menu-icon mdi mdi-account-multiple"></i>
        <span class="menu-title">Users</span>
        <i class="menu-arrow"></i>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['/riders']" id="nav-link1">
        <i class="menu-icon mdi mdi-bike"></i>
        <span class="menu-title">Riders</span>
        <i class="menu-arrow"></i>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['/companies']" id="nav-link1">
        <i class="menu-icon mdi mdi-home-modern"></i>
        <span class="menu-title">Companies</span>
        <i class="menu-arrow"></i>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['/codes']" id="nav-link1">
        <i class="menu-icon mdi mdi-barcode-scan"></i>
        <span class="menu-title">Rider ID Codes</span>
        <i class="menu-arrow"></i>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['/orders']" id="nav-link1">
        <i class="menu-icon mdi mdi-package-variant-closed"></i>
        <span class="menu-title">Orders</span>
        <i class="menu-arrow"></i>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['/withdrawal-requests']" id="nav-link1">
        <i class="menu-icon mdi mdi-credit-card-multiple"></i>
        <span class="menu-title">Withdrawal Requests</span>
        <i class="menu-arrow"></i>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['/post-requests']" id="nav-link1">
        <i class="menu-icon mdi mdi-book"></i>
        <span class="menu-title">Upload Requests</span>
        <i class="menu-arrow"></i>
      </a>
    </li>
  </ul>
</nav>
