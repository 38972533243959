<div class="row">
  <div class="col-md-6 d-flex align-items-stretch grid-margin">
    <div class="row flex-grow">
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Default form</h4>
            <p class="card-description">
              Basic form layout
            </p>
            <form class="forms-sample">
              <div class="form-group">
                <label for="exampleInputEmail1">Email address</label>
                <input type="email" class="form-control" id="exampleInputEmail1" placeholder="Enter email">
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1">Password</label>
                <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password">
              </div>
              <button type="submit" class="btn btn-success mr-2">Submit</button>
              <button class="btn btn-light">Cancel</button>
            </form>
          </div>
        </div>
      </div>
      <div class="col-12 stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Horizontal Form</h4>
            <p class="card-description">
              Horizontal form layout
            </p>
            <form class="forms-sample">
              <div class="form-group row">
                <label for="exampleInputEmail2" class="col-sm-3 col-form-label">Email</label>
                <div class="col-sm-9">
                  <input type="email" class="form-control" id="exampleInputEmail2" placeholder="Enter email">
                </div>
              </div>
              <div class="form-group row">
                <label for="exampleInputPassword2" class="col-sm-3 col-form-label">Password</label>
                <div class="col-sm-9">
                  <input type="password" class="form-control" id="exampleInputPassword2" placeholder="Password">
                </div>
              </div>
              <button type="submit" class="btn btn-success mr-2">Submit</button>
              <button class="btn btn-light">Cancel</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Basic form</h4>
        <p class="card-description">
          Basic form elements
        </p>
        <form class="forms-sample">
          <div class="form-group">
            <label for="exampleInputName1">Name</label>
            <input type="text" class="form-control" id="exampleInputName1" placeholder="Name">
          </div>
          <div class="form-group">
            <label for="exampleInputEmail3">Email address</label>
            <input type="email" class="form-control" id="exampleInputEmail3" placeholder="Email">
          </div>
          <div class="form-group">
            <label for="exampleInputPassword4">Password</label>
            <input type="password" class="form-control" id="exampleInputPassword4" placeholder="Password">
          </div>
          <div class="form-group">
            <label for="exampleInputCity1">City</label>
            <input type="text" class="form-control" id="exampleInputCity1" placeholder="Location">
          </div>
          <div class="form-group">
            <label for="exampleTextarea1">Textarea</label>
            <textarea class="form-control" id="exampleTextarea1" rows="2"></textarea>
          </div>
          <button type="submit" class="btn btn-success mr-2">Submit</button>
          <button class="btn btn-light">Cancel</button>
        </form>
      </div>
    </div>
  </div>
  <div class="col-md-6 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Coloured select box</h4>
        <p class="card-description">
          Basic bootstrap select in theme colors
        </p>
        <div class="form-group">
          <label for="exampleSelectPrimary">Primary</label>
          <select class="form-control border-primary" id="exampleSelectPrimary">
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </select>
        </div>
        <div class="form-group">
          <label for="exampleSelectInfo">Info</label>
          <select class="form-control border-info" id="exampleSelectInfo">
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </select>
        </div>
        <div class="form-group">
          <label for="exampleSelectSuccess">Success</label>
          <select class="form-control border-success" id="exampleSelectSuccess">
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </select>
        </div>
        <div class="form-group">
          <label for="exampleSelectDanger">Danger</label>
          <select class="form-control border-danger" id="exampleSelectDanger">
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </select>
        </div>
        <div class="form-group">
          <label for="exampleSelectWarning">Warning</label>
          <select class="form-control border-warning" id="exampleSelectWarning">
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6 d-flex align-items-stretch">
    <div class="row flex-grow">
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Basic input groups</h4>
            <p class="card-description">
              Basic bootstrap input groups
            </p>
            <div class="form-group">
              <div class="input-group">
                <span class="input-group-addon" id="basic-addon1">@</span>
                <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1">
              </div>
            </div>
            <div class="form-group">
              <div class="input-group">
                <span class="input-group-addon">$</span>
                <input type="text" class="form-control" aria-label="Amount (to the nearest dollar)">
                <span class="input-group-addon">.00</span>
              </div>
            </div>
            <div class="form-group">
              <div class="input-group">
                <span class="input-group-addon">$</span>
                <span class="input-group-addon">0.00</span>
                <input type="text" class="form-control" aria-label="Amount (to the nearest dollar)">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Colored input groups</h4>
            <p class="card-description">
              Input groups with colors
            </p>
            <div class="form-group">
              <div class="input-group">
                <span class="input-group-addon bg-info bg-info" id="colored-addon1">
                  <i class="mdi mdi-shield-outline text-white"></i>
                </span>
                <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="colored-addon1">
              </div>
            </div>
            <div class="form-group">
              <div class="input-group">
                <span class="input-group-addon bg-primary border-primary" id="colored-addon2">
                  <i class="mdi mdi-menu text-white"></i>
                </span>
                <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="colored-addon2">
              </div>
            </div>
            <div class="form-group">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="colored-addon3">
                <span class="input-group-addon bg-primary border-primary" id="colored-addon3">
                  <i class="mdi mdi-menu text-white"></i>
                </span>
              </div>
            </div>
            <div class="form-group">
              <div class="input-group">
                <span class="input-group-addon bg-primary">$</span>
                <input type="text" class="form-control" aria-label="Amount (to the nearest dollar)">
                <span class="input-group-addon bg-primary border-primary">.00</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="row">
        <div class="col-md-6">
          <div class="card-body">
            <h4 class="card-title">Input size</h4>
            <p class="card-description">
              This is the default bootstrap form layout
            </p>
            <div class="form-group">
              <label>Large input</label>
              <input type="text" class="form-control form-control-lg" placeholder="Username" aria-label="Username">
            </div>
            <div class="form-group">
              <label>Default input</label>
              <input type="text" class="form-control" placeholder="Username" aria-label="Username">
            </div>
            <div class="form-group">
              <label>Small input</label>
              <input type="text" class="form-control form-control-sm" placeholder="Username" aria-label="Username">
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card-body">
            <h4 class="card-title">Selectize</h4>
            <div class="form-group">
              <label for="exampleFormControlSelect1">Large select</label>
              <select class="form-control form-control-lg" id="exampleFormControlSelect1">
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
            </div>
            <div class="form-group">
              <label for="exampleFormControlSelect2">Default select</label>
              <select class="form-control" id="exampleFormControlSelect2">
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
            </div>
            <div class="form-group">
              <label for="exampleFormControlSelect3">Small select</label>
              <select class="form-control form-control-sm" id="exampleFormControlSelect3">
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 grid-margin">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Horizontal Two column</h4>
        <form class="form-sample">
          <p class="card-description">
            Personal info
          </p>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label">First Name</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label">Last Name</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label">Gender</label>
                <div class="col-sm-9">
                  <select class="form-control">
                    <option>Male</option>
                    <option>Female</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label">Date of Birth</label>
                <div class="col-sm-9">
                  <input class="form-control" placeholder="dd/mm/yyyy"/>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label">Category</label>
                <div class="col-sm-9">
                  <select class="form-control">
                    <option>Category1</option>
                    <option>Category2</option>
                    <option>Category3</option>
                    <option>Category4</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label">Membership</label>
                <div class="col-sm-4">
                  <div class="form-check">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" name="membershipRadios" id="membershipRadios1" value="" checked>
                      Free
                      <i class="input-helper"></i>
                    </label>
                  </div>
                </div>
                <div class="col-sm-5">
                  <div class="form-check">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" name="membershipRadios" id="membershipRadios2" value="option2">
                      Professional
                      <i class="input-helper"></i>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p class="card-description">
            Address
          </p>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label">Address 1</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label">State</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label">Address 2</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label">Postcode</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label">City</label>
                <div class="col-sm-9">
                  <input type="text" class="form-control" />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label">Country</label>
                <div class="col-sm-9">
                  <select class="form-control">
                    <option>America</option>
                    <option>Italy</option>
                    <option>Russia</option>
                    <option>Britain</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="col-md-6 grid-margin">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Typeahead</h4>
        <div class="row">
          <div class="col-md-6">
            <p class="card-description">
              Simple typeahead
            </p>
            <input id="typeahead-basic" type="text" class="form-control" [(ngModel)]="typeaheadBasicModel" [ngbTypeahead]="search"/>
          </div>
          <div class="col-md-6">
            <p class="card-description">
              Typeahead on focus
            </p>
            <input id="typeahead-focus" type="text" class="form-control" [(ngModel)]="typeaheadFocusModel"
              [ngbTypeahead]="focusSearch" (focus)="focus$.next($event.target.value)"
              (click)="click$.next($event.target.value)" #instance="ngbTypeahead"/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6 grid-margin">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Rating</h4>
        <div class="row">
          <div class="col-md-6">
            <p class="card-description">
              Basic rating
            </p>
            <ngb-rating [(rate)]="currentRate" class="rating"></ngb-rating>
          </div>
          <div class="col-md-6">
            <p class="card-description">
              Readonly rating
            </p>
            <ngb-rating [(rate)]="currentRate" [readonly]=true class="rating"></ngb-rating>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>